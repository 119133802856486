import React, { useEffect, useState, useRef } from "react";
import { List, Badge, Button, Popover, Typography, theme } from "antd";
import { format } from "date-fns";
import { BellOutlined, InboxOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";

const { Text } = Typography;

interface Notification {
  id: string;
  text: string;
  is_read: boolean;
  link?: string;
  created_at: string;
}

export const NotificationBell: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  useEffect(() => {
    fetchUnreadNotificationCount().then(setUnreadCount);
    fetchNotifications(1);
  }, []);

  const fetchNotifications = async (page: number) => {
    setLoading(true);
    try {
      const response = await globalAxiosInstance.get<Notification[]>(
        `${API_BASE_URL}/in_app_notifications?page=${page}`
      );
      const newNotifications = response.data || [];
      if (!Array.isArray(newNotifications))
        throw new Error("Invalid response format");

      setHasMore(newNotifications.length >= 5);
      setNotifications((prev) => [
        ...prev.filter((n) => !newNotifications.some((nn) => nn.id === n.id)),
        ...newNotifications,
      ]);

      // Scroll to the bottom of the container
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop =
            scrollContainerRef.current.scrollHeight;
        }
      }, 0);
    } catch (error) {
      console.error("Failed to fetch notifications", error);
      setHasMore(false); // Prevent further load attempts
    } finally {
      setLoading(false);
    }
  };

  const handleClearNotifications = async () => {
    // Optimistically update UI before making the API call
    setNotifications((prev) =>
      prev.map((n) => ({
        ...n,
        is_read: true, // Mark all notifications as read
      }))
    );
    setUnreadCount(0); // Reset the unread count in the badge

    try {
      const notificationIds = notifications.map((n) => n.id);
      await markNotificationsAsRead(notificationIds); // Call backend to sync state
    } catch (error) {
      console.error("Error marking notifications as read", error);

      // Revert UI changes if the API call fails
      setNotifications((prev) =>
        prev.map((n) => ({
          ...n,
          is_read: false, // Revert back to unread if API fails
        }))
      );
      setUnreadCount(notifications.filter((n) => !n.is_read).length); // Recalculate unread count
    }
  };

  const handleNotificationClick = async (item: Notification) => {
    if (!item.is_read) {
      await markNotificationsAsRead([item.id]);
      setNotifications((prev) =>
        prev.map((n) => (n.id === item.id ? { ...n, is_read: true } : n))
      );
      setUnreadCount((count) => Math.max(count - 1, 0));
    }
    if (item.link) {
      if (item.link.startsWith("/")) {
        window.location.href = window.location.origin + item.link;
      } else {
        window.open(item.link, "_blank");
      }
    }
  };

  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    return format(date, "MMMM d, yyyy · h:mm a");
  };

  const handleLoadMore = () => {
    if (hasMore) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchNotifications(nextPage);
        return nextPage;
      });
    }
  };

  return (
    <Popover
      content={
        notifications.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              color: token.colorTextSecondary,
            }}
          >
            <InboxOutlined style={{ fontSize: token.fontSizeXL }} />
            <p>No new notifications</p>
          </div>
        ) : (
          <>
            <div
              ref={scrollContainerRef}
              style={{
                maxHeight: "300px", // Set max height for the list container
                overflowY: "auto", // Enable vertical scrolling
                paddingRight: "8px", // Add padding for smooth scrolling
              }}
            >
              <List
                dataSource={notifications}
                renderItem={(item) => {
                  console.log(
                    `Rendering notification ID: ${item.id}, is_read: ${item.is_read}`
                  ); // Debug
                  return (
                    <List.Item
                      onClick={() => handleNotificationClick(item)}
                      style={{
                        padding: "10px 15px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BellOutlined
                          style={{
                            fontSize: "16px",
                            color: item.is_read ? "#b0b0b0" : "#1890ff",
                          }}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <Text strong={!item.is_read}>{item.text}</Text>
                          <Text
                            type="secondary"
                            style={{
                              display: "block",
                              marginTop: "4px",
                              fontSize: "12px",
                              color: "#b0b0b0",
                            }}
                          >
                            {formatTimestamp(item.created_at)}
                          </Text>
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
            {hasMore && (
              <Button
                type="dashed"
                block
                onClick={handleLoadMore}
                loading={loading}
                style={{
                  marginTop: token.marginMD,
                  color: token.colorPrimary,
                  borderColor: token.colorPrimary,
                }}
              >
                Load More
              </Button>
            )}
            <Button
              type="primary"
              block
              onClick={handleClearNotifications}
              style={{
                marginTop: token.marginSM,
                backgroundColor: token.colorPrimary,
                borderColor: token.colorPrimary,
              }}
            >
              Mark All as Read
            </Button>
          </>
        )
      }
      trigger="click"
    >
      <Badge count={unreadCount > 99 ? "99+" : unreadCount}>
        <BellOutlined
          style={{ fontSize: token.fontSizeXL, cursor: "pointer" }}
        />
      </Badge>
    </Popover>
  );
};

// Fetch unread notification count
const fetchUnreadNotificationCount = async (): Promise<number> => {
  try {
    const response = await globalAxiosInstance.get<{ unread_count: number }>(
      `${API_BASE_URL}/in_app_notifications/unread_count`
    );
    return response.data.unread_count;
  } catch (error) {
    console.error("Failed to fetch unread notification count", error);
    return 0;
  }
};

// Mark notifications as read
const markNotificationsAsRead = async (
  notificationIds: string[]
): Promise<void> => {
  try {
    await globalAxiosInstance.patch(
      `${API_BASE_URL}/in_app_notifications/mark_as_read`,
      {
        notification_ids: notificationIds,
      }
    );
  } catch (error) {
    console.error("Error marking notifications as read", error);
  }
};
