import { ClerkProvider } from "@clerk/clerk-react";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { init, replayIntegration } from "@sentry/react";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { getEnvironmentVariable } from "@/utilities/build-utils";

const initializeSentry = () => {
  init({
    dsn: getEnvironmentVariable("VITE_SENTRY_DSN"),
    integrations: [
      replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        event.extra = event.extra ?? {};
        event.extra["LogRocket"] = logRocketSession;
        return event;
      } else {
        return event;
      }
    },
  });
};

const initializeLogRocket = () => {
  LogRocket.init(getEnvironmentVariable("VITE_LOG_ROCKET_APP_ID"));
  setupLogRocketReact(LogRocket);
};

const initializeClerk = () => {
  return getEnvironmentVariable("VITE_CLERK_PUBLISHABLE_KEY");
};

if (import.meta.env.MODE === "production") {
  initializeSentry();
  initializeLogRocket();
}

const CLERK_PUBLISHABLE_KEY = initializeClerk();
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY} afterSignOutUrl="/">
      <App />
    </ClerkProvider>
  </React.StrictMode>
);
