import { Outlet, Route } from "react-router-dom";
import ChatInterfacePage from "@/pages/ai_assistant";

import LazyLoad from "@/components/lazy-load";
import { PlaceHolderPage } from "@/pages/placeholder";
import { i } from "@clerk/clerk-react/dist/useAuth-DT1ot2zi";

const getPartnerDashboardRoutes = () => {
  return (
    <Route
      path="/partner-dashboard"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/partner_dashboard").then((module) => ({
              default: module.PartnerDashboard,
            }))
          }
        />
      }
    />
  );
};

const getClientsRoutes = () => {
  return (
    <>
      <Route
        path="/clients"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/clients/list").then((module) => ({
                default: module.ClientsListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/client_records/create").then((module) => ({
                  default: module.ClientRecordCreatePage,
                }))
              }
            />
          }
        />
      </Route>
      <Route
        path="/clients/edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/partners/edit").then((module) => ({
                default: module.PartnerEditPage,
              }))
            }
          />
        }
      />

      <Route
        path="/clients/mapping"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/clients/client_mapping").then((module) => ({
                default: module.ClientMappingsPage,
              }))
            }
          />
        }
      />
    </>
  );
};

const getPartnerResourcesRoutes = () => {
  return (
    <Route
      path="partner-resources"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/partner_resources").then((module) => ({
              default: module.PartnerResourcesPage,
            }))
          }
        />
      }
    />
  );
};

const getAIAssistantRoutes = (isSuperAdmin: boolean) => {
  return isSuperAdmin ? (
    <Route path="/ai_assistant" element={<ChatInterfacePage />} />
  ) : (
    <PlaceHolderPage />
  );
};

const getPartnerTrainingRoutes = () => {
  return (
    <Route
      path="/partner-training"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/training/partner_list").then((module) => ({
              default: module.PartnerTrainingListPage,
            }))
          }
        />
      }
    />
  );
};

const getPartnerRevenueRoutes = () => {
  return (
    <>
      <Route path="/revenue" element={<Outlet />}>
        <Route
          path="deal-desk"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/deal_desk").then((module) => ({
                  default: module.DealDeskListPage,
                }))
              }
            >
              <Outlet />
            </LazyLoad>
          }
        >
          <Route
            path="create"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/deal_desk").then((module) => ({
                    default: module.DealDeskCreatePage,
                  }))
                }
              />
            }
          />
        </Route>
      </Route>
      <Route
        path="/revenue/deal-desk/show/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/deal_desk/view").then((module) => ({
                default: module.OpportunitiesPagePartnerView,
              }))
            }
          />
        }
      />
    </>
  );
};

export const getPartnerModeRoutes = ({
  isSuperAdmin,
}: {
  isSuperAdmin: boolean;
}) => {
  return (
    <>
      {/* Dashboard */}
      {getPartnerDashboardRoutes()}

      {/* Clients */}
      {getClientsRoutes()}

      {/* Enablement */}
      {getAIAssistantRoutes(isSuperAdmin)}
      {getPartnerResourcesRoutes()}
      {getPartnerTrainingRoutes()}

      {/* Revenue */}
      {getPartnerRevenueRoutes()}
    </>
  );
};
