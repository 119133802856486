import { getNameInitials, getRandomColorFromString } from "@/utilities";
import type { AvatarProps } from "antd";
import { Avatar as AntdAvatar, Tooltip } from "antd";
import { FC, memo } from "react";

type Props = AvatarProps & {
  name?: string;
  useToolTip?: boolean;
};

const CustomAvatarComponent: FC<Props> = ({
  name = "",
  useToolTip = false,
  style,
  ...rest
}) => {
  const avatar = (
    <AntdAvatar
      alt={name}
      size="small"
      style={{
        backgroundColor: rest?.src
          ? "transparent"
          : getRandomColorFromString(name),
        display: "flex",
        alignItems: "center",
        border: "none",
        objectFit: "contain",
        ...style,
      }}
      {...rest}
    >
      {getNameInitials(name)}
    </AntdAvatar>
  );
  if (useToolTip) {
    return <Tooltip title={name}>{avatar}</Tooltip>;
  }
  return avatar;
};

export const CustomAvatar = memo(
  CustomAvatarComponent,
  (prevProps, nextProps) => {
    return prevProps.name === nextProps.name && prevProps.src === nextProps.src;
  }
);
