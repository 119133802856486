import React, { useContext, useEffect } from "react";
import { AppModeContext } from "@/contexts/app-context";
import { ColorModeContext } from "@/contexts/color-mode";
import { useUserPreference } from "@/providers/identity";
import type { Identity as User } from "@/types";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";
import { NotificationBell } from "@/components/in-app-notifications";
import "./no-switch-color.css";
const { Text } = Typography;
const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<User>();
  const preferences = useUserPreference();
  const { colorMode, toggleColorMode } = useContext(ColorModeContext);
  const { mode: productMode, setMode: setProductMode } =
    useContext(AppModeContext);

  useEffect(() => {
    if (preferences && preferences.user_mode_state) {
      setProductMode(preferences.user_mode_state);
    }
  }, [preferences, setProductMode]);

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
    borderBottom: "1px solid var(--ant-color-border-secondary)",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <Switch
          checkedChildren="Partner"
          unCheckedChildren="Company"
          onChange={() =>
            setProductMode(productMode === "Partner" ? "Company" : "Partner")
          }
          defaultChecked={productMode === "Partner"}
          // className="no-color-switch"
        />

        <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => toggleColorMode()}
          defaultChecked={colorMode === "dark"}
        />
        <NotificationBell />
        <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.fullName && <Text strong>{user.fullName}</Text>}
          {user?.imageUrl && (
            <Avatar src={user?.imageUrl} alt={user?.fullName ?? ""} />
          )}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
