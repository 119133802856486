import { FC, useState } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  Typography,
  Space,
  Rate,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
  CloseOutlined,
  SendOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";

const { Text } = Typography;
const { Option } = Select;

interface Message {
  sender: string;
  message: string;
}

// Define session structure
interface Session {
  sessionId: string;
  partner: string;
  startTime: string;
  status: "Active" | "Closed" | "Pending";
  rating: number;
  summary: string;
  history: Message[];
}

interface ActivePartnersResponse {
  active_partners: { partner_company_id: string }[];
}

export const ChatInterfacePage: FC = () => {
  const [currentSessionID, setCurrentSessionID] = useState<string | null>(null); // Holds session ID
  const [chatInput, setChatInput] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false); // Simulate typing indicator
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedChatPartner, setSelectedChatPartner] = useState(""); // State for chat partner selection

  const { tableProps } = useTable<Session>({
    resource: "sessions",
    pagination: {
      pageSize: 5,
    },
  });

  const handleChatSubmit = async () => {
    if (chatInput.trim()) {
      // Add user's message to the chat history
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "User", message: chatInput },
      ]);

      const userMessage = chatInput;
      setChatInput(""); // Clear the input box
      setIsTyping(true);

      try {
        const activePartnersResponse =
          await globalAxiosInstance.get<ActivePartnersResponse>(
            `${API_BASE_URL}/sessions/active-partners`
          );

        console.log("activePartnersResponse");
        console.log(activePartnersResponse);
        console.log("ended");

        if (
          !activePartnersResponse.data.active_partners ||
          activePartnersResponse.data.active_partners.length === 0
        ) {
          console.error(
            "No active partners available for creating a chat session."
          );
          // return;
        }

        const selectedPartner = "1"; // activePartnersResponse.data.active_partners[0].partner_company_id;

        // Create session if none exists
        if (!currentSessionID) {
          const sessionResponse = await globalAxiosInstance.post(
            `${API_BASE_URL}/sessions`,
            {
              partner_company_id: selectedPartner,
              summary: "New chat session", // You can modify or make this dynamic
            }
          );
          const sessionID = sessionResponse.data.session_id;
          setCurrentSessionID(sessionID);
          console.log("Created new session:", sessionID);
        }

        // Save user's message to the session
        await globalAxiosInstance.post(
          `${API_BASE_URL}/sessions/${currentSessionID}/chats`,
          {
            sender: "User",
            message: userMessage,
          }
        );

        // Get the bot's response from the chatbot API
        const response = await globalAxiosInstance.post(
          `${API_BASE_URL}/chat`,
          {
            sender: "User",
            message: userMessage,
          }
        );

        // Add bot's response to chat history
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "Bot",
            message: response.data.response || "I didn't get that.",
          },
        ]);

        // Save bot's message to the session
        await globalAxiosInstance.post(
          `${API_BASE_URL}/sessions/${currentSessionID}/chats`,
          {
            sender: "Bot",
            message: response.data.response || "I didn't get that.",
          }
        );
      } catch (error) {
        console.error("Error:", error);

        // Display an error message in the chat history
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "Bot",
            message: "Oops! Something went wrong. Please try again.",
          },
        ]);
      } finally {
        // Stop typing indicator
        setIsTyping(false);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleChatSubmit();
    }
  };

  const handleExpand = (record) => {
    setSelectedSession(record);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedSession(null);
  };

  // Sample data
  const data: Session[] = [
    {
      sessionId: "001",
      partner: "Amplitude",
      startTime: "2023-11-14 10:30 AM",
      status: "Active",
      rating: 4,
      summary: "This session discussed account setup issues.",
      history: [
        {
          sender: "User",
          message: "Hello, I need help with my account setup.",
        },
        { sender: "Bot", message: "Sure, I can assist you with that!" },
        { sender: "User", message: "I can't access the setup page." },
        {
          sender: "Bot",
          message:
            "Please try clearing your browser cache and reload the page.",
        },
        { sender: "User", message: "Okay, that worked! Thanks." },
      ],
    },
    {
      sessionId: "002",
      partner: "Beta Industries Global Solutions",
      startTime: "2023-11-13 11:00 AM",
      status: "Closed",
      rating: 5,
      summary: "User inquired about billing details.",
      history: [
        { sender: "User", message: "Can you provide details on my billing?" },
        {
          sender: "Bot",
          message: "Your billing details can be found in the account section.",
        },
        { sender: "User", message: "I see a charge I don't recognize." },
        {
          sender: "Bot",
          message:
            "Let me connect you to our billing department for further assistance.",
        },
        { sender: "User", message: "Thanks, I appreciate it." },
      ],
    },
    {
      sessionId: "003",
      partner: "Gamma Solutions LLC",
      startTime: "2023-11-12 09:45 AM",
      status: "Pending",
      rating: 3,
      summary: "User asked about subscription renewal.",
      history: [
        { sender: "User", message: "How do I renew my subscription?" },
        {
          sender: "Bot",
          message: "You can renew your subscription from the settings page.",
        },
        { sender: "User", message: "Can I get a discount on renewal?" },
        {
          sender: "Bot",
          message: "Please contact our support team for discount information.",
        },
      ],
    },
    {
      sessionId: "004",
      partner: "Delta Innovations Co",
      startTime: "2023-11-11 02:30 PM",
      status: "Active",
      rating: 4,
      summary: "User needed assistance with product features.",
      history: [
        {
          sender: "User",
          message: "Can you explain the new feature in the latest update?",
        },
        {
          sender: "Bot",
          message:
            "Certainly! The new feature allows for better customization.",
        },
        { sender: "User", message: "Is it available in all plans?" },
        {
          sender: "Bot",
          message: "Yes, it’s available across all subscription plans.",
        },
        { sender: "User", message: "Thank you for the clarification." },
      ],
    },
    {
      sessionId: "005",
      partner: "Echo Partners International",
      startTime: "2023-11-10 04:00 PM",
      status: "Closed",
      rating: 2,
      summary: "User had an issue with payment processing.",
      history: [
        { sender: "User", message: "My payment isn't going through." },
        {
          sender: "Bot",
          message: "Could you check if your card information is correct?",
        },
        { sender: "User", message: "Yes, everything seems fine." },
        {
          sender: "Bot",
          message: "Let me escalate this to our payments team.",
        },
        {
          sender: "User",
          message: "Thanks, please let me know when it's resolved.",
        },
      ],
    },
    {
      sessionId: "006",
      partner: "Amplitude",
      startTime: "2023-11-10 03:15 PM",
      status: "Pending",
      rating: 3,
      summary: "User faced login issues.",
      history: [
        { sender: "User", message: "I can't log in to my account." },
        {
          sender: "Bot",
          message:
            "Have you tried resetting your password? Click 'Forgot Password' on the login page.",
        },
        {
          sender: "User",
          message: "I tried that, but I didn’t receive a reset email.",
        },
        {
          sender: "Bot",
          message:
            "Could you check your spam folder or verify your email address?",
        },
        {
          sender: "User",
          message: "It's correct, but still no email.",
        },
        {
          sender: "Bot",
          message:
            "I'll escalate this to our technical team. You’ll receive a resolution soon.",
        },
      ],
    },
    {
      sessionId: "007",
      partner: "Beta Industries Global Solutions",
      startTime: "2023-11-10 11:45 AM",
      status: "Active",
      rating: 5,
      summary: "Discussed a delay in product shipment.",
      history: [
        { sender: "User", message: "My order hasn’t arrived yet." },
        {
          sender: "Bot",
          message:
            "I’m sorry to hear that. Could you provide the order ID for tracking?",
        },
        { sender: "User", message: "It’s #12345ABC." },
        {
          sender: "Bot",
          message:
            "It shows your order is delayed due to weather conditions. It should arrive by tomorrow.",
        },
        { sender: "User", message: "Thanks for the update." },
        {
          sender: "Bot",
          message: "You’re welcome! Let us know if you need further help.",
        },
      ],
    },
    {
      sessionId: "008",
      partner: "Gamma Solutions LLC",
      startTime: "2023-11-09 01:30 PM",
      status: "Closed",
      rating: 2,
      summary: "User reported issues with mobile app functionality.",
      history: [
        { sender: "User", message: "The app keeps crashing when I open it." },
        {
          sender: "Bot",
          message:
            "I’m sorry for the trouble. Could you let me know your device model and app version?",
        },
        {
          sender: "User",
          message: "I’m using iPhone 13, version 3.2.1 of the app.",
        },
        {
          sender: "Bot",
          message:
            "Please try updating the app to the latest version. That often resolves crash issues.",
        },
        { sender: "User", message: "It’s already updated." },
        {
          sender: "Bot",
          message: "I’ll escalate this to the app team for investigation.",
        },
        { sender: "User", message: "Alright, thank you." },
      ],
    },
    {
      sessionId: "009",
      partner: "Delta Innovations Co",
      startTime: "2023-11-08 02:00 PM",
      status: "Active",
      rating: 4,
      summary: "User explored integrating with third-party services.",
      history: [
        {
          sender: "User",
          message: "How can I integrate your service with Slack?",
        },
        {
          sender: "Bot",
          message:
            "You can set up the integration via our integrations page under settings.",
        },
        { sender: "User", message: "Does it require admin privileges?" },
        {
          sender: "Bot",
          message:
            "Yes, admin access is needed to connect external apps like Slack.",
        },
        { sender: "User", message: "Thanks, I’ll ask my admin to set it up." },
        {
          sender: "Bot",
          message: "Great! Let us know if you need help during setup.",
        },
      ],
    },
    {
      sessionId: "010",
      partner: "Echo Partners International",
      startTime: "2023-11-07 10:45 AM",
      status: "Closed",
      rating: 3,
      summary: "User queried about invoice discrepancies.",
      history: [
        { sender: "User", message: "I see an incorrect amount on my invoice." },
        {
          sender: "Bot",
          message: "Could you share the invoice ID? I’ll check this for you.",
        },
        { sender: "User", message: "It’s INV-56789." },
        {
          sender: "Bot",
          message:
            "I see the discrepancy. It seems an additional service was incorrectly added. I’ll get this fixed.",
        },
        { sender: "User", message: "Please notify me once corrected." },
        {
          sender: "Bot",
          message: "Absolutely! You’ll receive an updated invoice shortly.",
        },
      ],
    },
    {
      sessionId: "011",
      partner: "Amplitude",
      startTime: "2023-11-07 04:30 PM",
      status: "Active",
      rating: 5,
      summary: "User explored exporting data from the dashboard.",
      history: [
        {
          sender: "User",
          message: "How do I export my data to a CSV file?",
        },
        {
          sender: "Bot",
          message:
            "Go to the dashboard, click on the settings menu, and select 'Export to CSV.'",
        },
        {
          sender: "User",
          message: "Can I filter the data before exporting?",
        },
        {
          sender: "Bot",
          message:
            "Yes, you can apply filters on the dashboard, and the export will respect those filters.",
        },
        { sender: "User", message: "That’s perfect, thanks!" },
        {
          sender: "Bot",
          message: "Happy to help! Let us know if you need anything else.",
        },
      ],
    },
    {
      sessionId: "012",
      partner: "Gamma Solutions LLC",
      startTime: "2023-11-06 09:20 AM",
      status: "Pending",
      rating: 3,
      summary: "User asked about cancellation policy.",
      history: [
        { sender: "User", message: "How do I cancel my subscription?" },
        {
          sender: "Bot",
          message:
            "You can cancel your subscription from the account settings page.",
        },
        {
          sender: "User",
          message: "Will I get a refund for the remaining period?",
        },
        {
          sender: "Bot",
          message:
            "Refunds are processed on a prorated basis for unused services.",
        },
        { sender: "User", message: "Thanks for the info." },
      ],
    },
    {
      sessionId: "013",
      partner: "Delta Innovations Co",
      startTime: "2023-11-05 02:45 PM",
      status: "Active",
      rating: 4,
      summary: "User explored API documentation.",
      history: [
        { sender: "User", message: "Where can I find the API documentation?" },
        {
          sender: "Bot",
          message:
            "You can access it on our developer portal under the 'Docs' section.",
        },
        { sender: "User", message: "Do you have examples of API requests?" },
        {
          sender: "Bot",
          message:
            "Yes, we provide sample requests and responses in the documentation.",
        },
        { sender: "User", message: "Great, I’ll check that out. Thanks!" },
      ],
    },
    {
      sessionId: "014",
      partner: "Echo Partners International",
      startTime: "2023-11-04 11:30 AM",
      status: "Closed",
      rating: 2,
      summary: "User had an issue with two-factor authentication.",
      history: [
        {
          sender: "User",
          message: "I’m not receiving the two-factor authentication code.",
        },
        {
          sender: "Bot",
          message:
            "Could you confirm your registered phone number or email address?",
        },
        {
          sender: "User",
          message: "It’s correct. I’ve checked multiple times.",
        },
        {
          sender: "Bot",
          message:
            "I’ll escalate this to our security team for further investigation.",
        },
        { sender: "User", message: "Please do. I’m locked out of my account." },
      ],
    },
    {
      sessionId: "015",
      partner: "Amplitude",
      startTime: "2023-11-03 03:10 PM",
      status: "Active",
      rating: 5,
      summary: "User discussed account data export.",
      history: [
        { sender: "User", message: "How do I export all my account data?" },
        {
          sender: "Bot",
          message:
            "You can request a data export from the privacy settings page.",
        },
        {
          sender: "User",
          message: "How long does it take to process the export?",
        },
        {
          sender: "Bot",
          message: "It typically takes 24–48 hours to generate the export.",
        },
        { sender: "User", message: "Thanks for letting me know!" },
      ],
    },
    {
      sessionId: "016",
      partner: "Beta Industries Global Solutions",
      startTime: "2023-11-02 04:00 PM",
      status: "Pending",
      rating: 4,
      summary: "User asked about adding team members to the account.",
      history: [
        { sender: "User", message: "How do I add team members to my account?" },
        {
          sender: "Bot",
          message:
            "You can invite team members from the 'Team Management' section in settings.",
        },
        {
          sender: "User",
          message: "Is there a limit to the number of team members I can add?",
        },
        {
          sender: "Bot",
          message:
            "The limit depends on your subscription plan. Check your plan details.",
        },
        { sender: "User", message: "Got it, thanks." },
      ],
    },
    {
      sessionId: "017",
      partner: "Delta Innovations Co",
      startTime: "2023-11-01 05:20 PM",
      status: "Active",
      rating: 5,
      summary: "User explored integrating Google Workspace.",
      history: [
        {
          sender: "User",
          message: "Can I integrate this with Google Workspace?",
        },
        {
          sender: "Bot",
          message:
            "Yes, we offer Google Workspace integration under the integrations page.",
        },
        { sender: "User", message: "Does it sync real-time with my calendar?" },
        {
          sender: "Bot",
          message:
            "Yes, it supports real-time syncing for calendars and contacts.",
        },
        { sender: "User", message: "Awesome, thanks for clarifying!" },
      ],
    },
  ];

  // Filtered data based on search and filter criteria
  const filteredData = data.filter((item) => {
    return (
      (item.partner.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.summary.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedPartner ? item.partner === selectedPartner : true) &&
      (selectedStatus ? item.status === selectedStatus : true)
    );
  });

  const columns = [
    {
      title: (
        <Input
          placeholder="Search by Partner or Summary"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "100%" }}
          prefix={<SearchOutlined />}
        />
      ),
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime: string) => <Text>{startTime}</Text>,
    },
    {
      title: (
        <Select
          placeholder="Filter by Partner"
          style={{ width: "100%" }}
          onChange={(value) => setSelectedPartner(value)}
          allowClear
        >
          {Array.from(new Set(data.map((item) => item.partner))).map(
            (partner) => (
              <Option key={partner} value={partner}>
                {partner}
              </Option>
            )
          )}
        </Select>
      ),
      dataIndex: "partner",
      key: "partner",
      render: (partner: string) => (
        <Space>
          <div
            style={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#87d068",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            {partner[0].toUpperCase()}
          </div>
          <Text ellipsis={{ tooltip: partner }}>
            {partner.length > 24 ? `${partner.slice(0, 21)}...` : partner}
          </Text>
        </Space>
      ),
    },
    {
      title: (
        <Select
          placeholder="Filter by Status"
          style={{ width: "100%" }}
          onChange={(value) => setSelectedStatus(value)}
          allowClear
        >
          <Option value="Active">Active</Option>
          <Option value="Closed">Closed</Option>
          <Option value="Pending">Pending</Option>
        </Select>
      ),
      dataIndex: "summary",
      key: "summary",
      render: (summary: string) => (
        <Text ellipsis={{ tooltip: summary }}>
          {summary.length > 40 ? `${summary.slice(0, 37)}...` : summary}
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Text>{status}</Text>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record: Session) => (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => handleExpand(record)}
        />
      ),
    },
  ];

  return (
    <div
      className="page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "70vh",
        padding: "40px 20px",
      }}
    >
      {/* Chat Header with Dropdown and Assistance Text */}
      <div
        className="chat-header-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center the entire header horizontally
          flexDirection: "column",
          marginBottom: "200px",
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            justifyContent: "flex-start", // Align items to the left
            width: "100%",
            maxWidth: "700px",
          }}
        >
          {/* Partner Selection Dropdown */}
          <Select
            placeholder="Select Partner"
            value={
              selectedChatPartner
                ? selectedChatPartner.length > 30
                  ? `${selectedChatPartner.slice(0, 30)}...`
                  : selectedChatPartner
                : undefined
            }
            onChange={(value) => setSelectedChatPartner(value)}
            style={{ width: "150px", minHeight: "30px" }}
            allowClear
            optionLabelProp="label"
          >
            {Array.from(new Set(data.map((item) => item.partner))).map(
              (partner) => (
                <Option
                  key={partner}
                  value={partner}
                  label={
                    <Space>
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          backgroundColor: "#e4c709",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        {partner[0].toUpperCase()}
                      </div>
                      <span
                        style={{
                          maxWidth: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {partner.length > 20
                          ? `${partner.slice(0, 20)}...`
                          : partner}
                      </span>
                    </Space>
                  }
                >
                  {partner}
                </Option>
              )
            )}
          </Select>

          {/* Assistance Text */}
          <Text strong style={{ fontSize: "18px" }}>
            How can I assist today?
          </Text>
        </div>

        {/* Chat Messages Display */}
        <div
          style={{
            width: "90%",
            height: "500px",
            marginTop: "20px",
            overflowY: "auto",
            border: "1px solid #e8e8e8",
            borderRadius: "10px",
            padding: "15px",
          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: msg.sender === "User" ? "row-reverse" : "row",
                margin: "5px 0",
              }}
            >
              <div
                style={{
                  maxWidth: "70%",
                  padding: "10px",
                  borderRadius: "8px",
                  backgroundColor:
                    msg.sender === "User" ? "#c8e8de" : "#f0f0f0",
                  color: "#333",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                <Text>{msg.message}</Text>
              </div>
            </div>
          ))}
          {isTyping && (
            <div
              style={{
                alignSelf: "flex-start",
                fontStyle: "italic",
                color: "#888",
              }}
            >
              Bot is typing...
            </div>
          )}
        </div>

        {/* Chat Input */}
        <div
          style={{
            display: "flex",
            width: "90%",
            gap: "10px",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Input
            type="text"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onPressEnter={handleKeyPress}
            placeholder="Type your message here"
            disabled={!selectedChatPartner}
            style={{
              width: "100%",
              height: "45px",
              fontSize: "13px",
              padding: "10px",
              borderRadius: "25px",
            }}
            suffix={
              <SendOutlined
                onClick={handleChatSubmit}
                style={{ cursor: "pointer", fontSize: "16px" }}
              />
            }
          />
        </div>
      </div>

      {/* Session Table */}
      <div
        className="session-table-container"
        style={{ maxWidth: "100%", marginTop: "-180px" }}
      >
        <Table<Session>
          {...tableProps}
          columns={columns}
          rowKey="sessionId"
          dataSource={filteredData}
          pagination={{ pageSize: 7 }}
          locale={{ emptyText: "No data" }}
        />
      </div>

      {/* Drawer for Session Details */}
      <Drawer
        title={`Session Details for ${selectedSession?.sessionId}`}
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width={400}
      >
        <SessionDetails session={selectedSession} />
      </Drawer>
    </div>
  );
};

// SessionDetails Component for the Drawer
const SessionDetails: FC<{ session: any }> = ({ session }) => {
  if (!session) return null;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Text strong>Partner:</Text>
      <Text>{session.partner}</Text>
      <Text strong>Start Time:</Text>
      <Text>{session.startTime}</Text>
      <Text strong>Status:</Text>
      <Text>{session.status}</Text>
      <Text strong>Rating:</Text>
      <Rate value={session.rating} />
      <Text strong>Summary:</Text>
      <Text>{session.summary}</Text>
      <Text strong>Conversation History:</Text>
      <div
        style={{
          maxHeight: "200px",
          overflowY: "auto",
          border: "1px solid #f0f0f0",
          padding: "8px",
        }}
      >
        {session.history.map((msg, index) => (
          <p key={index}>
            <strong>{msg.sender}:</strong> {msg.message}
          </p>
        ))}
      </div>
      <Button
        icon={<CloseOutlined />}
        type="dashed"
        onClick={() => console.log("End Session")}
      >
        End Session
      </Button>
    </Space>
  );
};

export default ChatInterfacePage;
