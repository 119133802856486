import { lazy, Suspense, ReactNode } from "react";
import LoadingWrapper from "./loading";

interface LazyLoadProps<T> {
  getComponent: Parameters<typeof lazy>["0"];
  fallback?: ReactNode;
  children?: ReactNode;
  componentProps?: T;
}

const LazyLoad = <T extends Record<string, unknown>>({
  getComponent,
  fallback = <LoadingWrapper loading />,
  children = "",
  componentProps,
}: LazyLoadProps<T>) => {
  const LazyComponent = lazy(getComponent);

  return (
    <Suspense fallback={fallback}>
      {children ? (
        <LazyComponent {...componentProps}>{children}</LazyComponent>
      ) : (
        <LazyComponent {...componentProps} />
      )}
    </Suspense>
  );
};

export default LazyLoad;
