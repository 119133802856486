import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useUpdate } from "@refinedev/core";
import { Button, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/lib";
import React, { useContext, useEffect, useMemo } from "react";
import { Text } from "./text";

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

type Props<T> = {
  tableProps: TableProps<T>;
  columns: ColumnsType<T>;
  resource: string;
  hideOrderColumn?: boolean;
};

export const SortableTable = <T extends { ID?: number; order?: number }>({
  tableProps,
  columns,
  resource,
  hideOrderColumn = true,
}: Props<T>) => {
  const [dataSource, setDataSource] = React.useState<T[]>([
    ...(tableProps?.dataSource ? [...tableProps.dataSource] : []),
  ]);

  const { mutate } = useUpdate();

  useEffect(() => {
    const sortedData = [...(tableProps?.dataSource || [])].sort(
      (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
    );
    setDataSource(sortedData);
  }, [tableProps?.dataSource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newDataSource = arrayMove(
        dataSource,
        dataSource.findIndex((item) => item.ID === active.id),
        dataSource.findIndex((item) => item.ID === over?.id)
      ).map((item, index) => ({
        ...item,
        order: index + 1, // 1 indexed, not 0
      }));

      setDataSource(newDataSource);

      mutate({
        resource: resource,
        id: "0",
        values: {
          updates: newDataSource.map((x) => ({
            id: x.ID,
            values: {
              order: x.order,
            },
          })),
        },
        successNotification: false,
      });
    }
  };

  const ids: number[] = [];

  dataSource?.forEach((i) => (i?.ID ? ids.push(i?.ID) : null));

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={ids} strategy={verticalListSortingStrategy}>
        <Table
          rowKey="ID"
          components={{ body: { row: Row } }}
          columns={[
            {
              key: "order",
              align: "center",
              width: 80,
              render: () => <DragHandle />,
            },
            {
              title: "Order",
              dataIndex: "order",
              key: "order",
              width: 40,
              hidden: hideOrderColumn,
              render: (_, record) => (
                <Space>
                  <Text style={{ whiteSpace: "nowrap" }}>{record?.order}</Text>
                </Space>
              ),
            },
            ...columns,
          ]}
          {...tableProps}
        />
      </SortableContext>
    </DndContext>
  );
};
