import LazyLoad from "@/components/lazy-load";
import { PlaceHolderPage } from "@/pages/placeholder";
import { Outlet, Route } from "react-router-dom";

const getCompanyDashboardRoutes = () => {
  return (
    <Route
      path="/dashboard"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/dashboard/index").then((module) => ({
              default: module.CompanyDashboard,
            }))
          }
        />
      }
    />
  );
};

const getPartnerRoutes = () => {
  return (
    <>
      <>
        <Route
          path="/partners"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partners/list").then((module) => ({
                  default: module.PartnerListPage,
                }))
              }
            >
              <Outlet />
            </LazyLoad>
          }
        >
          <Route
            path="create"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/partners/create").then((module) => ({
                    default: module.PartnerCreatePage,
                  }))
                }
              />
            }
          />
          <Route
            path="statuses/create"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/partners/kanban/create-stage").then(
                    (module) => ({
                      default: module.KanbanCreateStage,
                    })
                  )
                }
              />
            }
          />
          <Route
            path="statuses/edit/:id"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/partners/kanban/edit-stage").then(
                    (module) => ({
                      default: module.KanbanEditStage,
                    })
                  )
                }
              />
            }
          />
          <Route
            path="statuses/manage"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/partners/kanban/create-stage").then(
                    (module) => ({
                      default: module.KanbanCreateStage,
                    })
                  )
                }
              />
            }
          />
        </Route>
        <Route
          path="/partners/edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partners/edit").then((module) => ({
                  default: module.PartnerEditPage,
                }))
              }
            >
              <Outlet />
            </LazyLoad>
          }
        >
          <Route
            path="tasks/create"
            element={
              <LazyLoad
                getComponent={() =>
                  import("@/pages/tasks/create").then((module) => ({
                    default: module.TaskCreatePage,
                  }))
                }
                componentProps={{
                  partnerSpecific: true,
                }}
              />
            }
          />
        </Route>
      </>
    </>
  );
};

const getProspectingRoutes = () => {
  return (
    <>
      <Route path="/partners/prospecting" element={<PlaceHolderPage />} />
    </>
  );
};

const getPartnerObjectRoutes = () => {
  return (
    <>
      <Route
        path="/partners/types"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/partners/types/list").then((module) => ({
                default: module.PartnerTypesListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partners/types/create").then((module) => ({
                  default: module.PartnerTypeCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partners/types/edit").then((module) => ({
                  default: module.PartnerTypeEditPage,
                }))
              }
            />
          }
        />
        <Route
          path="show/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partners/types/show").then((module) => ({
                  default: module.PartnerTypeShowPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};
const getOpportunityObjectRoutes = () => {
  return (
    <>
      <Route
        path="/commissions/opportunities/types"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/opportunity/types/list").then((module) => ({
                default: module.OpportunityTypesListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/opportunity/types/create").then((module) => ({
                  default: module.OpportunityTypeCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/opportunity/types/edit").then((module) => ({
                  default: module.OpportunityTypeEditPage,
                }))
              }
            />
          }
        />
        <Route
          path="show/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/opportunity/types/show").then((module) => ({
                  default: module.OpportunityTypeShowPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};
const getContactObjectRoutes = () => {
  return (
    <>
      <Route
        path="/contacts/types"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/contacts/types/list").then((module) => ({
                default: module.ContactTypesListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contacts/types/create").then((module) => ({
                  default: module.ContactTypeCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contacts/types/edit").then((module) => ({
                  default: module.ContactTypeEditPage,
                }))
              }
            />
          }
        />
        <Route
          path="show/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contacts/types/show").then((module) => ({
                  default: module.ContactTypeShowPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};
const getExternalFieldRoutes = () => {
  return (
    <>
      <Route
        path="/partners/fields/external"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/custom_fields_external/list").then((module) => ({
                default: module.CustomFieldsExternalList,
              }))
            }
          />
        }
      />
    </>
  );
};

const getPartnerPagesRoutes = () => {
  return (
    <>
      <Route
        path="/partners/pages"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/partner_inbound/list").then((module) => ({
                default: module.PartnerInboundListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partner_inbound/create").then((module) => ({
                  default: module.PartnerInboundCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/partner_inbound/edit").then((module) => ({
                  default: module.PartnerInboundEditPage,
                }))
              }
            />
          }
        />
      </Route>
      <Route
        path="/partners/pages/opportunities/show/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/partner_inbound/show").then((module) => ({
                default: module.PartnerOpportunityShowPage,
              }))
            }
          />
        }
      />
    </>
  );
};

const getPartnerContractRoutes = (isSuperAdmin: boolean) => {
  return (
    <>
      <Route
        path="/partners/contracts"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/contracts/list").then((module) => ({
                default: module.ContractsListPage,
              }))
            }
            componentProps={{
              isSuperAdmin,
            }}
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contracts/create").then((module) => ({
                  default: module.ContractsCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="templates/create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contracts/templates/create").then((module) => ({
                  default: module.TemplateDocumentCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="signings/create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/contracts/signings/create").then((module) => ({
                  default: module.CreateSigning,
                }))
              }
            />
          }
        />
      </Route>
      <Route
        path="partners/contracts/templates/edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/contracts/templates/edit").then((module) => ({
                default: module.TemplateContractsEdit,
              }))
            }
          />
        }
      />
    </>
  );
};

const getPartnerMeetingsRoutes = () => {
  return (
    <>
      <Route
        path="/partners/meetings"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/meetings/list_page").then((module) => ({
                default: module.MeetingListPage,
              }))
            }
          />
        }
      />
    </>
  );
};

const getOpportunitiesRoutes = () => {
  return (
    <>
      <Route
        path="/commissions/opportunities"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/billing/opportunities").then((module) => ({
                default: module.OpportunitiesListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/billing/create").then((module) => ({
                  default: module.CreateOpportunitiesPage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/billing/opportunities_edit").then((module) => ({
                  default: module.EditOpportunitiesPage,
                }))
              }
              componentProps={{
                enableStatus: true,
              }}
            />
          }
        />
        <Route
          path="convert/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/referral-pages/show").then((module) => ({
                  default: module.ReferralsShowPage,
                }))
              }
              componentProps={{
                close_route: "opportunities",
              }}
            />
          }
        />
        <Route
          path="notes/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/referral-pages/notes").then((module) => ({
                  default: module.ViewEditNotesDrawer,
                }))
              }
              componentProps={{
                close_route: "opportunities",
              }}
            />
          }
        />
      </Route>
      <Route
        path="/commissions/opportunities/show/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/billing/opportunities_view").then((module) => ({
                default: module.OpportunitiesPageView,
              }))
            }
            componentProps={{
              enableStatus: true,
            }}
          />
        }
      />
    </>
  );
};
const getCommissionRoutes = () => {
  return (
    <>
      <Route
        path="/commissions/structure"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/billing/commission").then((module) => ({
                default: module.CommissionStructurePage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/billing/commission_edit").then((module) => ({
                  default: module.EditCommissionsPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};

const getReferralPagesRoutes = () => {
  return (
    <>
      <Route
        path="/referral-pages"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/referral-pages/list").then((module) => ({
                default: module.ReferralPageListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/referral-pages/create").then((module) => ({
                  default: module.ReferralPageCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/referral-pages/edit").then((module) => ({
                  default: module.ReferralPageEditPage,
                }))
              }
            />
          }
        />
        <Route
          path="opportunities/show/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/referral-pages/show").then((module) => ({
                  default: module.ReferralsShowPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};
const getAutomationRoutes = (isSuperAdmin: boolean) => {
  return (
    <>
      <Route
        path="/automations"
        element={
          isSuperAdmin ? (
            <LazyLoad
              getComponent={() =>
                import("@/pages/automations/list").then((module) => ({
                  default: module.AutomationListPage,
                }))
              }
            >
              <Outlet />
            </LazyLoad>
          ) : (
            <PlaceHolderPage />
          )
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/automations/create").then((module) => ({
                  default: module.AutomationCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/automations/edit").then((module) => ({
                  default: module.AutomationEditPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};

const getTasksRoutes = (isSuperAdmin: boolean) => {
  return (
    <Route
      path="/tasks"
      element={
        isSuperAdmin ? (
          <LazyLoad
            getComponent={() =>
              import("@/pages/tasks/list").then((module) => ({
                default: module.TaskListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        ) : (
          <PlaceHolderPage />
        )
      }
    >
      <Route
        path="create"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/tasks/create").then((module) => ({
                default: module.TaskCreatePage,
              }))
            }
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/tasks/edit").then((module) => ({
                default: module.TaskEditPage,
              }))
            }
          />
        }
      />
    </Route>
  );
};
const getEmailCampaignRoutes = (isSuperAdmin: boolean) => {
  return (
    <Route
      path="/email_campaign"
      element={
        isSuperAdmin ? (
          <LazyLoad
            getComponent={() =>
              import("@/pages/emailCampaign").then((module) => ({
                default: module.EmailCampagin,
              }))
            }
          />
        ) : (
          <PlaceHolderPage />
        )
      }
    />
  );
};

const getAIAssistant = (isSuperAdmin: boolean) => {
  return (
    <Route
      path="/ai_assistant"
      element={
        isSuperAdmin ? (
          <LazyLoad
            getComponent={() =>
              import("@/pages/ai_assistant/company_view_index").then(
                (module) => ({
                  default: module.ChatInterfaceTableCompany,
                })
              )
            }
          />
        ) : (
          <PlaceHolderPage />
        )
      }
    />
  );
};

const getResourcesRoutes = () => {
  return (
    <Route
      path="/resources"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/resources").then((module) => ({
              default: module.ResourcesPage,
            }))
          }
        />
      }
    />
  );
};

const getTrainingRoutes = () => {
  return (
    <>
      <Route
        path="/training"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/training/list").then((module) => ({
                default: module.TrainingListPage,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        }
      >
        <Route
          path="create"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/training/create").then((module) => ({
                  default: module.TrainingCreatePage,
                }))
              }
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <LazyLoad
              getComponent={() =>
                import("@/pages/training/edit").then((module) => ({
                  default: module.TrainingEditPage,
                }))
              }
            />
          }
        />
      </Route>
    </>
  );
};

const getFeedbackRoutes = () => {
  return <Route path="/feedback" element={<PlaceHolderPage />} />;
};

const getReferralsAuthedRoutes = () => (
  <>
    {/* entry for this route is not there in resources, @TODO */}
    <Route
      path="/referrals_authed/:id"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/referrals/create").then((module) => ({
              default: module.ReferralPage,
            }))
          }
          componentProps={{
            authed: true,
          }}
        />
      }
    />
  </>
);

export const getCompanyModeRoutes = ({
  isSuperAdmin,
}: {
  isSuperAdmin: boolean;
}) => {
  return (
    <>
      {getCompanyDashboardRoutes()}

      {/* Partners */}
      {getPartnerRoutes()}
      {getPartnerPagesRoutes()}
      {getPartnerContractRoutes(isSuperAdmin)}
      {getProspectingRoutes()}
      {getPartnerMeetingsRoutes()}

      {/* Data Manager */}
      {getPartnerObjectRoutes()}
      {getOpportunityObjectRoutes()}
      {getContactObjectRoutes()}
      {getExternalFieldRoutes()}

      {/* AI agents */}
      {getAutomationRoutes(isSuperAdmin)}
      {getTasksRoutes(isSuperAdmin)}

      {/* Revenue */}
      {getOpportunitiesRoutes()}
      {getReferralPagesRoutes()}
      {getCommissionRoutes()}

      {getReferralsAuthedRoutes()}

      {/* Enablement */}
      {getAIAssistant(isSuperAdmin)}
      {getResourcesRoutes()}
      {getTrainingRoutes()}
      {getFeedbackRoutes()}
      {getEmailCampaignRoutes(isSuperAdmin)}
    </>
  );
};
