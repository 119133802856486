export const checkEnvVariables = (variableName: string) => {
  if (!import.meta.env[variableName]) {
    throw new Error(
      `${variableName} not found in env variables in mode:${
        import.meta.env.MODE
      }`
    );
  }
};

export const getEnvironmentVariable = (variableName: string) => {
  checkEnvVariables(variableName);
  return import.meta.env[variableName];
};
