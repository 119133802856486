import { Preference, User } from "@/types";
import { useCustom } from "@refinedev/core";
import { API_BASE_URL } from "./data";

export const useIdentity = () => {
  const { data: me } = useCustom<User>({
    url: `${API_BASE_URL}/me`,
    method: "get",
  });
  return me?.data;
};

export const useUserPreference = () => {
  const { data } = useCustom<Preference>({
    url: `${API_BASE_URL}/user/preference`,
    method: "get",
  });
  return data?.data;
};
